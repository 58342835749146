<template>
  <b-card no-body>
    <b-col class="text-right">
      <!-- <b-button variant="primary" @click="exportPDF" @dblclick="exportPDF"> -->
        <b-button variant="primary" @click="exportPDF">

        <feather-icon icon="DownloadIcon" class="mr-50" />PDF</b-button
      >
    </b-col>

    <pdfexport
      ref="contentToExport"
      forcePageBreak=".page-break"
      :margin="'0.5cm'"
      :paper-size="'A4'"
      :keepTogether="'.prevent-split'"
      :enable-download="'true'"
    >
      <b-row style="width: 100%;text-align: center;">
        <b-col cols="12">
          <strong
            style="
              text-align: center;
              font-size: 27px;
              background: #e9e9e9;
              border: 2px solid #131111;
              color: black;"
          >
            Inspection Report
          </strong>
        </b-col>
      </b-row>

      <b-card-body class="p-0" style="padding: 1.5rem !important">
        <b-row>
          <b-col cols="6"
            ><b>Type</b> :
            {{ allDetails.type == 1 ? "Pre Trip" : "Post Trip" }}</b-col
          >
          <b-col cols="6"
            ><b>Vehicle Number</b> : {{ allDetails.tractor_id }}</b-col
          >
          <b-col cols="6"
            ><b>Odometer Reading</b> : {{ allDetails.odometer }}</b-col
          >
          <b-col cols="6"
            ><b>Date</b> : {{ dateFormat(allDetails.date) }}</b-col
          >
          <b-col cols="6"
            ><b>Time</b> : {{ timeFormat(allDetails.date) }}</b-col
          >
        </b-row>
        <br />
        <h4>List of defects from inspection</h4>
        <hr />
        <b-row v-for="(Citem, Cindex) in selected_category_data" :key="Cindex">
          <h5 class="defect-show-title">{{ Citem.title }}</h5>

          <b-col
            cols="12"
            md="2"
            v-for="(item, index) in CategoryWiseData(Citem.id)"
            :key="index"
          >
            <b-form-group>
              <b-form-checkbox
                v-model="selected"
                :value="item.id"
                :name="item.title"
                :disabled="true"
                >{{ item.title }}
                <div v-if="selected.includes(item.id) > 0">
                  <b-link v-if="validateIcon(item.id, 1)">
                    <feather-icon
                      icon="FileTextIcon"
                      size="21"
                      @click="ViewDefectDetails(item.id, 1)"
                    />
                  </b-link>

                  <b-link v-if="validateIcon(item.id, 2)">
                    <feather-icon
                      icon="VideoIcon"
                      size="21"
                      @click="ViewDefectDetails(item.id, 2)"
                    />
                  </b-link>

                  <b-link v-if="validateIcon(item.id, 3)">
                    <feather-icon
                      icon="MailIcon"
                      size="21"
                      @click="ViewDefectDetails(item.id, 3)"
                    />
                  </b-link>
                </div>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <h4>Comments from driver from inspection</h4>
        <hr />
        <b-row>
          <b-col cols="12">Comments : {{ allDetails.feedback }}</b-col>
        </b-row>
        <br />
        <hr />
        <h4>Remarks : {{ allDetails.remarks }}</h4>

        <hr />
        <b-row style="line-height: 47px">
          <b-col cols="4"
            ><b>Driver</b> : <br />{{ allDetails.driver_name }}</b-col
          >
          <b-col cols="4"
            ><b>Signature</b> : <br /><img
              :src="allDetails.signature"
              width="100px"
          /></b-col>
        </b-row>
      </b-card-body>
    </pdfexport>

    <b-modal
      size="lg"
      id="modal-message"
      centered
      title="Message"
      ok-only
      ok-title="Cancel"
      ref="modal-message"
    >
      {{ MessageDetails }}
    </b-modal>

  </b-card>
</template>
    
    <script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BButton,
  BFormDatepicker,
  BTable,
  BFormCheckbox,
  BPagination,
  BOverlay,
  BFormInvalidFeedback,
  BDropdownItem,
  BDropdown,
  BLink,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
// import 'vue-good-table/dist/vue-good-table.css'
import { heightTransition } from "@core/mixins/ui/transition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import axios from "axios";
import moment from "moment";
import { serverUri } from "@/config";
import { getUserData } from "@/auth/utils";
import FeatherIcon from "../../../@core/components/feather-icon/FeatherIcon.vue";
import router from "@/router";
import { PDFExport } from "@progress/kendo-vue-pdf";

export default {
  components: {
    VueGoodTable,
    BCard,
    BCardHeader,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BFormDatepicker,
    BTable,
    BFormCheckbox,
    BPagination,
    vSelect,
    BOverlay,
    BFormInvalidFeedback,
    BDropdownItem,
    BDropdown,
    FeatherIcon,
    BLink,
    pdfexport: PDFExport,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      MessageDetails: "",
      fileUrl: serverUri + `/upload/`,
      selected: [],
      selectedData: [],
      driver: "",
      option: [],
      category_data: [],
      selected_category_data: [],
      defectCategory:[],
      showUpdate: false,
      show: true,
      perPage: 25,
      pageOptions: [5, 10, 15, 25],
      totalRows: 1,
      currentPage: 1,
      vehicle_defects: [],
      vehicle_defects_data: [],
      selectedDefects: [],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: " ",
      history_list: [],
      filterOn: [],
      selectedDriver: "",
      selectedModalDvir: "",
      startDate: "",
      endDate: "",
      companyId: getUserData().company_id,
      allDetails: [],
      fields: [
        {
          key: "driver_name",
          label: "Driver Name",
          formatter: (value) => {
            return value.toUpperCase();
          },
        },
        {
          key: "date",
          label: "Date",
          formatter: (value) => {
            return moment(value).format("MM/DD/YYYY");
          },
        },

        {
          key: "tractor_id",
          label: "Vehicle",
          sortable: false,
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "odometer",
          label: "Odometer",
          sortable: false,
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "remarks",
          label: "Remarks",
          sortable: false,
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "action",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
  },
  mounted() {
    this.getCategories();
    this.getVehicleDefects();
    this.SelectedVehicleDefects(router.currentRoute.params.id);
  },
  created() {
    this.getInspectionsList();
    window.addEventListener("resize", this.initTrHeight);
    this.SelectedVehicleDefects(router.currentRoute.params.id);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    exportPDF: function () {

      axios
        .post(`${serverUri}/filter/inspections_list`,{
          company_id : this.companyId,
          vehicle_id : [],
          start_date : '',
          end_date   : '',
          driver     : this.selectedDriver == undefined || this.selectedDriver == '' ? '' : this.driver.title
        })
        .then((res) => {
          if (res.data.status) 
          {
            this.pdfView = res.data.pdf,
            this.history_list = res.data.data;
            this.showUpdate = false;

            let pdfWindow = window.open("pdf_view");
            pdfWindow.document.write("<iframe width='100%' height='100%' src='data:application/pdf;base64, " + encodeURI(this.pdfView) + "'></iframe>");
          }
        });
      // this.pdfStatus = true;
      // // console.log(this.$refs.contentToExport);
      // this.$refs.contentToExport.save();
      // this.pdfStatus = false;


    },

    dateFormat(date) {
      return moment(date).format("MM/DD/YYYY");
    },

    timeFormat(time) {
      return moment(time).format("H:MM a");
    },

    validateIcon(id, type) {
      const Output = [];
      this.selectedData.map((item, index) => {
        if (item.id == id && type == 1) {
          if (item.file != "") {
            const imageExts = ["jpg", "jpeg", "png", "gif", "pdf"];
            const ext = item.file.split(".").pop();
            console.log(ext);
            if (imageExts.includes(ext)) {
              Output.push(true);
            }
          }
        } else if (item.id == id && type == 2) {
          const imageExts = ["mov", "mp4", "wmv", "avi", "mkv", "webm"];
          const ext = item.file.split(".").pop();
          console.log(ext);
          if (imageExts.includes(ext)) {
            Output.push(true);
          }
        } else if (item.id == id && type == 3) {
          if (item.message != "") {
            Output.push(true);
          }
        }
      });

      return Output.length > 0 ? true : false;
    },

    // ViewDefectDetails()
    // {

    // },

    getInspectionsList() {
      axios
        .get(`${serverUri}/inspections_list/${this.companyId}`)
        .then((res) => {
          if (res.data) {
            this.history_list = res.data;
          }
        });
    },

    ViewDefectDetails(defectId, type) {
      const resultI = [];
      const resultV = [];
      const resultM = [];
      this.selectedDefects.map((item, index) => {
        if (
          this.selectedModalDvir == item.dvir_id &&
          item.vehicle_defect_id == defectId
        ) {
          if (type == 1) {
            const imageExts = ["jpg", "jpeg", "png", "gif", "pdf"];
            const ext = item.file.split(".").pop();
            console.log(ext);
            if (imageExts.includes(ext)) {
              resultI.push(this.fileUrl + item.file);
            }
          } else if (type == 2) {
            const imageExts = ["mov", "mp4", "wmv", "avi", "mkv", "webm"];
            const ext = item.file.split(".").pop();
            console.log(ext);
            if (imageExts.includes(ext)) {
              resultV.push(this.fileUrl + item.file);
            }
          } else {
            if (item.message != "") {
              resultM.push(item.message);
            }
          }
        }
      });

      if (type == 1) {
        if (resultI.length == 0) {
          alert("No file found.");
          return false;
        }
        window.open(resultI[0], "_blank");
      } else if (type == 2) {
        if (resultV.length == 0) {
          alert("No video file found.");
          return false;
        }

        window.open(resultV[0], "_blank");
      } else {
        if (resultM.length == 0) {
          alert("No message found.");
          return false;
        } else {
          this.MessageDetails = resultM[0];
            this.$refs["modal-message"].show();
        }
      }
    },

    SelectedVehicleDefects(id) {
      // this.selectedDefects = data;
      this.selectedModalDvir = id;

      this.selected = [];
      this.selectedData = [];
      axios.get(`${serverUri}/inspections_defects_list/${id}`).then((res) => {
        if (res.data) {
          this.selectedDefects = res.data;

          this.selectedDefects.map((item, index) => {
            if (id == item.dvir_id) {
              this.defectCategory.push(item.defect_category);
              this.selected.push(item.vehicle_defect_id);
              this.selectedData.push({
                file: item.file,
                message: item.message,
                id: item.vehicle_defect_id,
              });

              // console.log(this.defectCategory);

              this.defectCategory = [ ...new Set(this.defectCategory) ]

              console.log(this.defectCategory);

              // this.defectCategory.filter((item, index, self) => {
              //           return self.indexOf(item) === index;
              //       });

            }
          });


          this.category_data.map((item, index) => {
                if(this.defectCategory.includes(item.id))
                {
                  this.selected_category_data.push(item);
                }
            });
        }
      });

      axios.get(`${serverUri}/single_inspection_defect/${id}`).then((res) => {
        if (res.data) {
          this.allDetails = res.data[0];
        }
      });

      this.show = false;
    },
    CategoryWiseData(catId) {
      const AllData = [];
      this.vehicle_defects.map((item, index) => {
        if (catId == item.category_id && item.status == 1) {
          AllData.push(item);
        }
      });
      return AllData;
    },

    getCategories() {
      axios
        .get(
          `${serverUri}/inspections/vehicle_defects_category/${this.companyId}`
        )
        .then((res) => {
          res.data.map((item, index) => {
            this.category_data.push(item);
          });
        });
    },

    getVehicleDefects() {
      axios
        .get(`${serverUri}/inspections/vehicle_defects/${this.companyId}`)
        .then((res) => {
          res.data.map((item, index) => {
            this.vehicle_defects.push(item);
          });
        });
    },

    filterAllData() {
      this.showUpdate = true;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId),
      });

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight);
      });
    },
    removeItem(index) {
      this.items.splice(index, 1);
      this.trTrimHeight(this.$refs.row[0].offsetHeight);
    },
    initTrHeight() {
      this.trSetHeight(null);
      // this.$nextTick(() => {
      //     this.trSetHeight(this.$refs.form.scrollHeight);
      // });
    },

    selectionChanged(params) {
      this.selectedItems = params.selectedRows;
    },

    changeYear() {
      this.getFilteredMMR();
    },

    changeMonth() {
      this.getFilteredMMR();
    },

    onChange() {
      this.selectedDriver = this.driver?.title;
    },
    exportPDFs(data) {
      axios
        .post(`${serverUri}/fleet/getselectedmaintenance`, {
          tractor_id: data.formattedRow.tractor_id,
          domicile: data.formattedRow.domicile,
          month: moment().month(data.formattedRow.month).format("M"),
        })
        .then(async (res) => {
          var temp = await res.data;

          if (temp.length) {
            this.mmrData = {
              signature: this.signature,
              monthYear: this.selectedMonth + " " + this.selectedYear,
              domicile: data.formattedRow.domicile,
              serviceName: data.formattedRow.name,
              odometer: data.formattedRow.odometer
                ? Math.round(data.formattedRow.odometer)
                : "",
              tractor: data.formattedRow.tractor_id,
              mmrTask: data.formattedRow.mmr_task,
              oos: data.formattedRow.out_of_service,
              tArr: temp,
              dateCompleted: moment(this.selectedDate).format("MM/DD/YYYY"),
            };

            setTimeout(() => {
              this.$refs.contentToExport.save();
            }, 1000);
          }
        });
    },

    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },
  },
};
</script>
    
    <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.vgt-table th {
  font-size: 13px;
}

.hiddenContainer {
  position: absolute;
  left: -10000px;
  top: 0;
}
button.btn.btn-danger {
  margin-left: 10px;
}
.modal-dialog-centered {
  max-width: 80%;
}
.defect-show-title {
  width: 100%;
  padding: 10px 15px;
}
input[type="checkbox"][readonly] {
  pointer-events: none;
}
</style>
    